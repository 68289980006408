
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfirmInput',
  components: {
  },
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    class: {
      type: String,
    },
  },
  setup (props) {
    return {
      props,
    }
  },
})
