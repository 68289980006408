
import { defineComponent, computed } from 'vue'
import ConfirmInput from '@/components/organisms/dms/common/ConfirmInput.vue'

export default defineComponent({
  name: 'CollectInfoConfirm',
  components: {
    ConfirmInput,
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const selectedValue = computed({
      get: () => props.modelValue,
      set: (selectedValue) =>
        emit('update:modelValue', selectedValue),
    })
    return {
      props,
      selectedValue,
    }
  },
})
