
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ItemConfirm from '@/components/organisms/dms/OnlineConfirm/ItemConfirm.vue'
import UrlConfirm from '@/components/organisms/dms/OnlineConfirm/UrlConfirm.vue'
import CollectInfoConfirm from '@/components/organisms/dms/OnlineConfirm/CollectInfoConfirm.vue'
import PermissionConfirm from '@/components/organisms/dms/OnlineConfirm/PermissionConfirm.vue'
import UserDataConfirm from '@/components/organisms/dms/OnlineConfirm/UserDataConfirm.vue'
import OthersConfirm from '@/components/organisms/dms/OnlineConfirm/OthersConfirm.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { postDataCredential } from '@/helpers/util/webApiUtil'
import { AutoCollectApplicant, AutoCollectOnlineData, AutoCollectUrl } from '@/data/dms/dmsData'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OnlineConfirm',
  components: {
    ItemConfirm,
    UrlConfirm,
    CollectInfoConfirm,
    PermissionConfirm,
    UserDataConfirm,
    OthersConfirm,
    DssIcon,
    AppButton,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const autoCollectOnlineData = store.getters.autoCollectData.autoCollectOnlineData ? ref(store.getters.autoCollectData.autoCollectOnlineData) : ref<AutoCollectOnlineData>({
      name: '',
      edition: '',
      volume: '',
      author: '',
      publisher: '',
      publishYear: '',
      publishFrom: '',
      publishTo: '',
      codeType: '',
      code: '',
    })
    const autoCollectUrl = store.getters.autoCollectData.autoCollectUrl ? ref(store.getters.autoCollectData.autoCollectUrl) : ref<AutoCollectUrl>({
      pageUrl: '',
      fileUrl: '',
    })
    const regularlyCollect = store.getters.autoCollectData.regularlyCollect ? ref(store.getters.autoCollectData.regularlyCollect) : ref<string>('')
    const permission = store.getters.autoCollectData.permission ? ref(store.getters.autoCollectData.permission) : ref<string>('')
    const autoCollectApplicant = store.getters.autoCollectData.autoCollectApplicant ? ref(store.getters.autoCollectData.autoCollectApplicant) : ref<AutoCollectApplicant>({
      name: '',
      institution: '',
      mailAddress: '',
      mailAddressCheck: '',
      relation: '',
    })
    const others = store.getters.autoCollectData.others ? ref(store.getters.autoCollectData.others) : ref<string>('')

    const sendData = store.getters.autoCollectData

    const setAutoCollectData = async (autoCollectData: any) => {
      await store.dispatch('setAutoCollectData', autoCollectData)
    }

    onMounted(async () => {
      if (store.getters.autoCollectData.autoCollectApplicant === undefined) {
        await router.push('/dms/online/agreement')
      }
    })

    return {
      router,
      autoCollectOnlineData,
      autoCollectUrl,
      regularlyCollect,
      autoCollectApplicant,
      others,
      sendData,
      permission,

      async send () {
        const url = `${BASE_URL}/outer/auto/collect/entry`
        const body = JSON.stringify(sendData)
        const response = await postDataCredential(url, body, true)
        if (response.status === 200) {
          await setAutoCollectData({})
          await router.push({ name: 'OnlineCompletePage', params: { receiptNumber: response.data.receiptNumber } })
        }
      },

      async backToInput () {
        await router.push({ name: 'OnlineInputPage', params: { agreement: 'true' } })
      },
    }
  },
})
